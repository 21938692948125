/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

// Elements
import { Image, ImageBackground, ParseContent, SEO, ContainerLeft } from 'components/shared'
import { Button, CustomerCase } from 'components/elements'

// Layout
import Layout from 'components/layout/Layout'

// Animation
import Lottie from 'react-lottie'
import * as grafiekData from 'src/animations/UBX_Grafiek.json'
import * as megafoonData from 'src/animations/UBX_Megafoon.json'
import * as tandwielenData from 'src/animations/UBX_tandwielen.json'

// Images
import PlayIcon from 'img/play.svg'
import BackImg from 'img/back-logo.png'

// const BannerContent = styled(ParseContent)`
//   text-shadow: 0 0 4px rgba(0, 0, 0.25);

//   h5 {
//     text-shadow: none;
//     letter-spacing: 3px;
//     font-weight: 500;
//   }

//   @media (max-width: 575px) {
//     & h1, & h2 {
//       font-size: ${props => props.theme.font.size.l};
//     }

//     & p {
//       font-size: ${props => props.theme.font.size.sm};
//     }
//   }
// `

const OpdrachtContent = styled(ParseContent)`
  position: relative;
  top: 50px;

  h2 {
    margin-bottom: 20px;
  }

  p {
    line-height: 24px;
    padding-right: 40px;
    margin-bottom: 25px;
  }

  h5 {
    letter-spacing: 3px;
    font-weight: 500;
    font-size: ${(props) => props.theme.font.size['18']};
  }
`

const WerkContent = styled(ParseContent)`
  h2 {
    margin-bottom: 60px;
    font-size: ${(props) => props.theme.font.size['40']};
  }

  h2,
  h4 {
    color: ${(props) => props.theme.color.text.main} !important;
  }

  h4 {
    font-size: ${(props) => props.theme.font.size['28']} !important;
  }

  p {
    color: ${(props) => props.theme.color.text.main} !important;
    font-weight: 600;
    line-height: 27px;
  }
`

const WebsiteContent = styled(ParseContent)`
  h2 {
    margin-bottom: 20px;
    font-size: ${(props) => props.theme.font.size['40']};
  }

  p {
    line-height: 24px;
  }
`

const CampagneContent = styled(ParseContent)`
  h2 {
    margin-bottom: 20px;
    font-size: ${(props) => props.theme.font.size['40']};
  }

  p {
    line-height: 24px;
    font-weight: 300;
  }
`

const PageTemplate = ({
  data: {
    page: { slug, path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout>
    <SEO yoast={yoast} path={path} type="article" />

    {/* HERO */}
    <Hero fields={acf.banner} />

    {/* <Hero src={acf.banner.image} className="d-flex align-items-center">
      <div className="container pb-lg-5">
        <div className="col-lg-6 mb-lg-5">
          <BannerContent content={acf.banner.description} />
        </div>
      </div>
    </Hero> */}

    {/* START */}
    <StartWrapper className="d-flex justify-content-center">
      <Button to="/werkwijze">Start</Button>
    </StartWrapper>

    <UBXImage>
      <Image src={BackImg} style={{ width: '100%' }} />
    </UBXImage>

    {/* OPDRACHT */}
    <Assignment className="container pb-lg-5 pb-3 px-lg-0">
      <div className="row mx-0">
        <div className="col-lg-6 pb-lg-5">
          <OpdrachtContent content={acf.opdracht.description} />
        </div>
        <div className="col-lg-6 pb-lg-5">
          {slug === 'digibord-shop' && (
            <Lottie
              options={{
                animationData: megafoonData.default,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height="100%"
              width="100%"
            />
          )}

          {slug === 'profield' && (
            <Lottie
              options={{
                animationData: grafiekData.default,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height="100%"
              width="100%"
            />
          )}

          {slug === 'aazoo' && (
            <Lottie
              options={{
                animationData: tandwielenData.default,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height="100%"
              width="100%"
            />
          )}
        </div>
      </div>
    </Assignment>

    {/* WERKWIJZE */}
    <div className="container">
      <UniqWorking className="mt-5">
        <div className="row">
          <div className="col-lg-8">
            <WerkContent className="p-4 p-lg-0" content={acf.werkwijze.description} />
          </div>
        </div>
      </UniqWorking>
    </div>

    {/* WEBSITE */}
    <div className="container d-flex flex-wrap px-lg-0">
      <Website className="col-lg-6 pt-lg-5 pt-3">
        <WebsiteContent
          className="pt-5 mt-4"
          content={acf.content.description}
        />
      </Website>
      <div className="col-lg-6 d-lg-block d-none position-relative">
        <LaptopContainer>
          <Image src={acf.content.image} alt="laptop" />
        </LaptopContainer>
      </div>
    </div>

    {/* Campagne */}
    {acf.content_2.description && (
      <Campaign className="container">
        <div className="col-lg-7">
          <CampagneContent content={acf.content_2.description} />
        </div>
      </Campaign>
    )}

    {/* Klantcase */}
    <div className="container">
      <CustomerSection className="mt-5 pt-4">
        <CustomerCase fields={acf.klantcase} />
      </CustomerSection>
    </div>

    <div className="py-lg-5" />

    {acf.content_2.video !== 'empty' && (
      <PlayerContainer>
        <ReactPlayer
          url={acf.content_2.video}
          playIcon={<img src={PlayIcon} alt="play" />}
          width="100%"
        />
      </PlayerContainer>
    )}

    <div className="py-5" />
  </Layout>
)

const LaptopContainer = styled.div`
  position: absolute;
  margin-top: -100px;
  right: 0;
  width: 90%;

  @media screen and (max-width: 1024px) {
    position: static;
    margin-top: 10px;
  }
`

const PlayerContainer = styled.div`
  padding-bottom: 150px;
`

const Website = styled.div`
  h3 {
    font-size: ${(props) => props.theme.font.size.xxl};
    color: ${(props) => props.theme.color.face.light};
    font-family: ${(props) => props.theme.font.family.main};
    margin-top: 100px;
  }
  p {
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.face.light};
    font-family: ${(props) => props.theme.font.family.secondary};
  }
`

const Campaign = styled.div`
  h3 {
    font-size: ${(props) => props.theme.font.size.xxl};
    color: ${(props) => props.theme.color.face.light};
    font-family: ${(props) => props.theme.font.family.main};
  }
  p {
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.face.light};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  padding-top: 60px;
  padding-bottom: 120px;

  @media screen and (max-width: 991px) {
    padding-top: 0;
    padding-bottom: 140px;
  }
`

const CustomerSection = styled.div`
  padding-bottom: 10px;
  background: ${(props) => props.theme.color.face.main};
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 0;
  }
`

const UniqWorking = styled.div`
  background: white;
  padding: 45px 100px;
  h3 {
    font-size: ${(props) => props.theme.font.size.xxl};
    color: ${(props) => props.theme.color.face.main};
    font-family: ${(props) => props.theme.font.family.main};
  }
  h4 {
    font-size: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.face.main};
    font-family: ${(props) => props.theme.font.family.main};
  }
  p {
    font-size: ${(props) => props.theme.font.size.sm};
    color: ${(props) => props.theme.color.face.main};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  @media screen and (max-width: 991px) {
    padding: 0;

    h3 {
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }
`

const Assignment = styled.div`
  margin-top: 80px;
  h3 {
    font-size: ${(props) => props.theme.font.size.xxl};
    font-family: ${(props) => props.theme.font.family.main};
    color: white;
    margin-top: 90px;
  }
  p {
    font-size: ${(props) => props.theme.font.size.sm};
    font-family: ${(props) => props.theme.font.family.secondary};
    color: white;
  }
  .link {
    font-size: ${(props) => props.theme.font.size.xm};
    font-family: ${(props) => props.theme.font.family.secondary};
    color: ${(props) => props.theme.color.face.secondary};
  }
`

const StartWrapper = styled.div`
  margin-top: -25px;
  position: relative;
`

// const Hero = styled(ImageBackground)`
//   height: 80vh;
//   min-height: 850px;
//   .case {
//     color: ${(props) => props.theme.color.face.secondary};
//     font-size: ${(props) => props.theme.font.size.xm};
//   }
//   h1 {
//     font-size: ${(props) => props.theme.font.size.xxl};
//     font-family: ${(props) => props.theme.font.family.main};
//   }
//   p {
//     font-size: ${(props) => props.theme.font.size.m};
//     font-family: ${(props) => props.theme.font.family.secondary};
//   }
//   @media screen and (max-width: 1024px) {
//     height: 560px;
//     h1 {
//       font-size: ${(props) => props.theme.font.size.xl};
//       font-family: ${(props) => props.theme.font.family.main};
//     }
//   }
// `

const HeroContainer = styled.div`
  position: relative;
  margin-top: 0px;
`

const HeroContent = styled(ContainerLeft)`
  color: ${(props) => props.theme.color.text.light};
  margin-top: 120px;

  @media screen and (max-width: 991px) {
    margin-top: 60px;
    margin-left: 0;
  }

  h1 {
    font-family: ${(props) => props.theme.font.family.main};

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.l};
    }
  }

  h4 {
    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xm};
    }
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.l};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  @media screen and (max-width: 456px) {
    margin-top: 40px;

    p {
      font-size: ${(props) => props.theme.font.size.sm};
      line-height: ${(props) => props.theme.font.size.l};
    }
  }
`

const Content = styled(ParseContent)`
  & td {
    vertical-align: unset;
  }

  & tr {
    height: auto !important;

    td {
      height: auto !important;
    }

    @media screen and (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;

      td {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }
`

const HeroImage = styled(ImageBackground)`
  width: 100%;

  @media (min-width: 992px) {
    min-height: 640px;
    height: 100%;
  }

  @media (max-width: 991px) {
    min-height: 0;
    padding-top: 50%;
    height: auto;
  }

  @media screen and (max-width: 456px) {
    min-height: 480px;
    height: 480px;
    max-height: 480px;
    background-position: 40% 50% !important;

    &:before {
      background-position: 40% 50% !important;
    }

    &:after {
      background-position: 60% 50% !important;
    }
  }
`

const Hero = ({ fields }) => (
  <HeroContainer className="d-flex flex-wrap">
    <div className="w-100 w-lg-50 mb-5 mb-lg-0 px-3">
      <HeroContent>
        <Content content={fields.description} />
      </HeroContent>
    </div>
    <div className="w-100 w-lg-50">
      <HeroImage
        src={fields.image}
        style={{ width: '100%', height: '100%', minHeight: 500 }}
      />
    </div>
  </HeroContainer>
)

const UBXImage = styled.div`
  /* height: 420px; */
  pointer-events: none;
  width: 80%;
  margin-top: -80px;
  position: absolute;
  background-size: contain !important;
  background-position-x: 0 !important;
  @media screen and (max-width: 768px) {
    margin-top: 80px;
    width: 100%;
  }

  & .gatsby-image-wrapper {
    max-height: 250px;
    overflow: initial !important;

    & img {
      max-height: 350px;
      object-fit: contain;
    }
  }
`
export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpCase(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      slug

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        opdracht {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        werkwijze {
          description
        }

        content {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        klantcase {
          quote
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        content_2 {
          description
          video
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
